<template>
<base-layout page-title="Login">

<ion-grid>
  <ion-row >
    <ion-col class="ion-text-center" style="margin-top: 140px" >
    <img :src="logo" style="width: 180px"/> 
</ion-col>
  </ion-row>
<ion-row >
    <ion-col class="ion-text-center">
    <ion-button type="button" @click="login" v-if="!currentUser" color="primary">Login</ion-button>
    <ion-button type="button" router-link="/tabs/menu/" v-if="currentUser" color="primary">Menu</ion-button>
    </ion-col>
  </ion-row>
</ion-grid>
    </base-layout>
</template>

<script>
import logo from '../assets/img/DailyGringLogo.png';
//import {IonButton, IonGrid, IonCol, IonRow} from '@ionic/vue';
import firebase from 'firebase';
import {auth} from '../services/firebase';

export default {
    components: {
       
    },
    data() {
      return {logo};
  },
    computed: {
        currentUser() {
            return this.$store.getters.loggedInUser;
        }
    },
    methods: {
        async login() {
            const provider = new firebase.auth.GoogleAuthProvider();
            await auth.signInWithPopup(provider);
            this.$router.replace('/');
        },
    async logoff() {
        await auth.signOut();
        }
    }
}
</script>